import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebHeader from "../assets/WebHeader.png";
import WebHeaderT from "../thumbnails/WebHeader.png";
import MobileHeader from "../assets/MobileHeader.png";
import FrameDecor from "../assets/FrameDecor.png";
import MobileHeaderT from "../thumbnails/MobileHeader.png";
import WebVideo from "../video/N3KO WEB TRAILER v3.h264.mp4";
import MobileVideo from "../video/N3KO VERTICAL TRAILER V3.h264.mp4";

let video = null;

class Header extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (video) {
        if (
          -video.getBoundingClientRect().top >
          (video.getBoundingClientRect().height * 2) / 3
        ) {
          video.pause();
        } else {
          video.play();
        }
      }
    });
    window.addEventListener("click", () => {
      if (video) {
        video.muted = false;
      }
    });
  }

  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebHeader} placeholder={WebHeaderT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1024,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <video
                src={WebVideo}
                style={{
                  width: (this.props.width / 1440) * 829,
                  height: (this.props.width / 1440) * 468,
                  position: "absolute",
                  left: (this.props.width / 1440) * 325,
                  top: (this.props.width / 1440) * 291,
                  objectFit: "cover",
                }}
                ref={(x) => {
                  if (x) {
                    video = x;
                  }
                }}
                preload="auto"
                autoPlay
                loop
                muted
              />
            </div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileHeader} placeholder={MobileHeaderT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 844,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <video
                src={MobileVideo}
                style={{
                  width: (this.props.width / 390) * 306,
                  height: (this.props.width / 390) * 541,
                  position: "absolute",
                  left: (this.props.width / 390) * 49,
                  top: (this.props.width / 390) * 166,
                  objectFit: "cover",
                }}
                ref={(x) => {
                  if (x) {
                    video = x;
                  }
                }}
                onTouchMove={() => {
                  if (video) {
                    video.muted = false;
                  }
                }}
                preload="auto"
                autoPlay
                loop
                playsInline
                muted
              />
              <div
                style={{
                  width: (this.props.width / 390) * 384.22,
                  height: (this.props.width / 390) * 517,
                  backgroundImage: `url("${FrameDecor}")`,
                  backgroundSize: "cover",
                  position: "absolute",
                  top: (this.props.width / 390) * 107,
                  left: (this.props.width / 390) * 6,
                }}
              ></div>
            </div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Header.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Header.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Header;
