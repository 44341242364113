import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import ProgressiveImage from "react-progressive-image";
import WebRoadmap from "../assets/WebRoadmap.png";
import pass2 from "../assets/2pass.png";
import pass3 from "../assets/3pass.png";
import pass4 from "../assets/4pass.png";
import pass5 from "../assets/5pass.png";
import norm2 from "../assets/2.png";
import norm3 from "../assets/3.png";
import norm4 from "../assets/4.png";
import norm5 from "../assets/5.png";
import WebRoadmapT from "../thumbnails/WebRoadmap.png";
import MobileRoadmap from "../assets/MobileRoadmap.png";
import MobileRoadmapT from "../thumbnails/MobileRoadmap.png";
import pass2mob from "../assets/2passmob.png";
import pass3mob from "../assets/3passmob.png";
import pass4mob from "../assets/4passmob.png";
import pass5mob from "../assets/5passmob.png";
import norm2mob from "../assets/2mob.png";
import norm3mob from "../assets/3mob.png";
import norm4mob from "../assets/4mob.png";
import norm5mob from "../assets/5mob.png";

class Roadmap extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebRoadmap} placeholder={WebRoadmapT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1146,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: (this.props.width / 1440) * 341,
                  height: (this.props.width / 1440) * 164,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-02-17"), "second") > 0
                      ? pass2
                      : norm2
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 1440) * 535,
                  left: (this.props.width / 1440) * 193,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 1440) * 323,
                  height: (this.props.width / 1440) * 149,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-02-18"), "second") > 0
                      ? pass3
                      : norm3
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 1440) * 842,
                  left: (this.props.width / 1440) * 252,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 1440) * 281,
                  height: (this.props.width / 1440) * 144,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-03-01"), "second") > 0
                      ? pass4
                      : norm4
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 1440) * 470,
                  left: (this.props.width / 1440) * 966,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 1440) * 426,
                  height: (this.props.width / 1440) * 94,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-03-18"), "second") > 0
                      ? pass5
                      : norm5
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 1440) * 657,
                  left: (this.props.width / 1440) * 820,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileRoadmap} placeholder={MobileRoadmapT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1736,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: (this.props.width / 390) * 288,
                  height: (this.props.width / 390) * 74,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-02-17"), "second") > 0
                      ? pass2mob
                      : norm2mob
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 390) * 550,
                  left: (this.props.width / 390) * 23,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 390) * 274,
                  height: (this.props.width / 390) * 106,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-02-18"), "second") > 0
                      ? pass3mob
                      : norm3mob
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 390) * 744,
                  left: (this.props.width / 390) * 63,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 390) * 241,
                  height: (this.props.width / 390) * 94,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-03-01"), "second") > 0
                      ? pass4mob
                      : norm4mob
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 390) * 1082,
                  left: (this.props.width / 390) * 98,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                style={{
                  width: (this.props.width / 390) * 330,
                  height: (this.props.width / 390) * 70,
                  backgroundImage: `url("${
                    moment().diff(moment("2022-03-18"), "second") > 0
                      ? pass5mob
                      : norm5mob
                  }")`,
                  backgroundSize: "contain",
                  position: "absolute",
                  top: (this.props.width / 390) * 1233,
                  left: (this.props.width / 390) * 9,
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Roadmap.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Roadmap.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Roadmap;
