import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebEthos from "../assets/WebEthos.png";
import WebEthosT from "../thumbnails/WebEthos.png";
import MobileEthos from "../assets/MobileEthos.png";
import MobileEthosT from "../thumbnails/MobileEthos.png";

class Ethos extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebEthos} placeholder={WebEthosT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1024,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileEthos} placeholder={MobileEthosT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1418,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Ethos.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Ethos.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Ethos;
