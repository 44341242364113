import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebStory from "../assets/WebStory.png";
import WebStoryT from "../thumbnails/WebStory.png";
import MobileStory from "../assets/MobileStory.png";
import MobileStoryT from "../thumbnails/MobileStory.png";
import Banner from "./Banner";

class Story extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebStory} placeholder={WebStoryT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1024,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", top: 0, left: 0 }}>
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 1440) * 42}
                  portrait={this.props.portrait}
                />
              </div>
              <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 1440) * 42}
                  portrait={this.props.portrait}
                />
              </div>
            </div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileStory} placeholder={MobileStoryT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1102,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 390) * 23}
                  portrait={this.props.portrait}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 390) * 23}
                  portrait={this.props.portrait}
                />
              </div>
            </div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Story.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Story.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Story;
