import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebWelcome from "../assets/WebWelcome.png";
import WebWelcomeT from "../thumbnails/WebWelcome.png";
import WebWelcomeOverlay from "../assets/WebWelcomeOverlay.png";
import WebWelcomeOverlayT from "../thumbnails/WebWelcomeOverlay.png";
import MobileWelcome from "../assets/MobileWelcome.png";
import MobileWelcomeT from "../thumbnails/MobileWelcome.png";
import MobileWelcomeOverlay from "../assets/MobileWelcomeOverlay.png";
import MobileWelcomeOverlayT from "../thumbnails/MobileWelcomeOverlay.png";
import JoinBtn from "../assets/JoinBtn.png";
import JoinBtnM from "../assets/JoinBtnMobile.png";
import Twitter from "../assets/Twitter.png";
import Ins from "../assets/Ins.png";
import Discord from "../assets/Discord.png";
import OP3N from "../assets/OP3N.png";
import GrainTexture from "../assets/Grain-Texture.png";
import Wave from "../assets/Wave.png";

class Welcome extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebWelcome} placeholder={WebWelcomeT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1911,
                backgroundColor: "#FFF2A9",
                position: "relative",
              }}
            >
              <div
                className="wave"
                style={{
                  width: "100%",
                  height: "68.65%",
                  backgroundImage: `url("${Wave}")`,
                  backgroundSize: "cover",
                  backgroundPositionY: "bottom",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("${src}")`,
                  backgroundSize: "cover",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("${GrainTexture}")`,
                  backgroundSize: "cover",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  mixBlendMode: "overlay",
                }}
              ></div>
              <ProgressiveImage
                src={WebWelcomeOverlay}
                placeholder={WebWelcomeOverlayT}
              >
                {(src) => (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url("${src}")`,
                      backgroundSize: "cover",
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                  ></div>
                )}
              </ProgressiveImage>
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 1440) * 445,
                  height: (this.props.width / 1440) * 73.4,
                  backgroundImage: `url("${JoinBtn}")`,
                  left: (this.props.width / 1440) * 498,
                  top: (this.props.width / 1440) * 764,
                }}
                onClick={() => window.open("https://op3n.world/projects/n3ko-house/membership")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 1440) * 48,
                  height: (this.props.width / 1440) * 47.7,
                  backgroundImage: `url("${Twitter}")`,
                  left: (this.props.width / 1440) * 1109,
                  top: (this.props.width / 1440) * 1774,
                }}
                onClick={() => window.open("https://twitter.com/n3koshouse")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 1440) * 48,
                  height: (this.props.width / 1440) * 47.7,
                  backgroundImage: `url("${Ins}")`,
                  left: (this.props.width / 1440) * 1180,
                  top: (this.props.width / 1440) * 1774,
                }}
                onClick={() =>
                  window.open("https://www.instagram.com/op3nworld/")
                }
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 1440) * 48,
                  height: (this.props.width / 1440) * 47.7,
                  backgroundImage: `url("${Discord}")`,
                  left: (this.props.width / 1440) * 1251,
                  top: (this.props.width / 1440) * 1774,
                }}
                onClick={() => window.open("https://discord.gg/JXrpVAjP")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 1440) * 48,
                  height: (this.props.width / 1440) * 47.7,
                  backgroundImage: `url("${OP3N}")`,
                  left: (this.props.width / 1440) * 1322,
                  top: (this.props.width / 1440) * 1774,
                }}
                onClick={() =>
                  window.open("https://op3n.world/projects/n3ko-house/membership")
                }
              />
            </div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileWelcome} placeholder={MobileWelcomeT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1074,
                backgroundColor: "#FFF2A9",
                position: "relative",
              }}
            >
              <div
                className="wave"
                style={{
                  width: "100%",
                  height: "37.24%",
                  backgroundImage: `url("${Wave}")`,
                  backgroundSize: "cover",
                  backgroundPositionY: "bottom",
                  backgroundPositionX: "var(--x)",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("${src}")`,
                  backgroundSize: "cover",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url("${GrainTexture}")`,
                  backgroundSize: "cover",
                  position: "absolute",
                  left: 0,
                  top: 0,
                  mixBlendMode: "overlay",
                }}
              ></div>
              <ProgressiveImage
                src={MobileWelcomeOverlay}
                placeholder={MobileWelcomeOverlayT}
              >
                {(src) => (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url("${src}")`,
                      backgroundSize: "cover",
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                  ></div>
                )}
              </ProgressiveImage>
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 390) * 288,
                  height: (this.props.width / 390) * 47.5,
                  backgroundImage: `url("${JoinBtnM}")`,
                  left: (this.props.width / 390) * 48,
                  top: (this.props.width / 390) * 573,
                }}
                onClick={() => window.open("https://op3n.world/projects/n3ko-house/membership")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 390) * 48,
                  height: (this.props.width / 390) * 47.7,
                  backgroundImage: `url("${Twitter}")`,
                  left: (this.props.width / 390) * 65,
                  top: (this.props.width / 390) * 993,
                }}
                onClick={() => window.open(" https://twitter.com/n3koshouse")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 390) * 48,
                  height: (this.props.width / 390) * 47.7,
                  backgroundImage: `url("${Ins}")`,
                  left: (this.props.width / 390) * 136,
                  top: (this.props.width / 390) * 993,
                }}
                onClick={() =>
                  window.open("https://www.instagram.com/op3nworld/")
                }
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 390) * 48,
                  height: (this.props.width / 390) * 47.7,
                  backgroundImage: `url("${Discord}")`,
                  left: (this.props.width / 390) * 278,
                  top: (this.props.width / 390) * 993,
                }}
                onClick={() => window.open("https://discord.gg/JXrpVAjP")}
              />
              <div
                className="click-btn"
                style={{
                  width: (this.props.width / 390) * 48,
                  height: (this.props.width / 390) * 47.7,
                  backgroundImage: `url("${OP3N}")`,
                  left: (this.props.width / 390) * 207,
                  top: (this.props.width / 390) * 993,
                }}
                onClick={() =>
                  window.open("https://op3n.world/projects/n3ko-house/membership")
                }
              />
            </div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Welcome.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Welcome.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Welcome;
