import PropTypes from "prop-types";
import React from "react";
import BannerImg from "../assets/Banner.png";

class Banner extends React.Component {
  render() {
    const oneLen = (this.props.height / 42) * 2026;
    return (
      <div
        className="banner"
        style={{
          position: "relative",
          height: this.props.height,
          width: oneLen * 4,
          backgroundImage: `url("${BannerImg}")`,
          backgroundSize: "25% 100%",
          backgroundPosition: "left",
          animationDuration: `${(
            (this.props.width / oneLen) *
            (this.props.portrait ? 15 : 70)
          ).toFixed(3)}s`,
        }}
      />
    );
  }
}

Banner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  portrait: PropTypes.bool,
};

Banner.defaultProps = {
  width: 2026,
  height: 42,
  portrait: true,
};

export default Banner;
