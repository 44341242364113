import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebMint from "../assets/WebMint.png";
import WebMintT from "../thumbnails/WebMint.png";
import MobileMint from "../assets/MobileMint.png";
import MobileMintT from "../thumbnails/MobileMint.png";

class Mint extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebMint} placeholder={WebMintT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1180,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileMint} placeholder={MobileMintT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1033,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Mint.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Mint.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Mint;
