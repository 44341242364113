import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebFaq from "../assets/WebFaq.png";
import WebFaqT from "../thumbnails/WebFaq.png";
import MobileFaq from "../assets/MobileFaq.png";
import MobileFaqT from "../thumbnails/MobileFaq.png";
import Banner from "./Banner";

class Faq extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebFaq} placeholder={WebFaqT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1505,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", top: 0, left: 0 }}>
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 1440) * 42}
                  portrait={this.props.portrait}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 1440) * 42}
                  portrait={this.props.portrait}
                />
              </div>
            </div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileFaq} placeholder={MobileFaqT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 1280,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 390) * 23}
                  portrait={this.props.portrait}
                />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                }}
              >
                <Banner
                  width={this.props.width}
                  height={(this.props.width / 390) * 23}
                  portrait={this.props.portrait}
                />
              </div>
            </div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Faq.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Faq.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Faq;
