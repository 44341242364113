import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import WebTeam from "../assets/WebTeam.png";
import WebTeamT from "../thumbnails/WebTeam.png";
import MobileTeam from "../assets/MobileTeam.png";
import MobileTeamT from "../thumbnails/MobileTeam.png";

class Team extends React.Component {
  render() {
    if (this.props.portrait) {
      return (
        <ProgressiveImage src={WebTeam} placeholder={WebTeamT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 1440) * 1266,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    } else {
      return (
        <ProgressiveImage src={MobileTeam} placeholder={MobileTeamT}>
          {(src) => (
            <div
              style={{
                width: this.props.width,
                height: (this.props.width / 390) * 736,
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </ProgressiveImage>
      );
    }
  }
}

Team.propTypes = {
  width: PropTypes.number,
  portrait: PropTypes.bool,
};

Team.defaultProps = {
  width: 1440,
  portrait: true,
};

export default Team;
